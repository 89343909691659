import React from "react"
import { graphql, Link } from "gatsby"
import { SEO, CtaLink } from "components/atoms"
import { BlogPreview, TagButtonList } from "components/molecules"
import { Layout } from "components/pageLayouts"

const BlogIndex = ({ data }) => {
    const posts = data.allMarkdownRemark.edges
    const firstPost = posts[0]
    const remainingPosts = posts.slice(1)

    return (
        <Layout>
            <div className={"row"}>
                <div className={"col"}>
                    {<BlogPreview post={firstPost} large={true} />}
                </div>
            </div>
            <div className={"row"}>
                <div className={`col-lg-8`}>
                    {remainingPosts.map(post => (
                        <BlogPreview
                            post={post}
                            large={false}
                            key={post.node.id}
                        />
                    ))}
                </div>
                <div className={`col-lg-4`}>
                    <TagButtonList />
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <CtaLink href={"/blog/2"} direction={"right"}>
                        Older Posts
                    </CtaLink>
                </div>
            </div>
        </Layout>
    )
}
export default BlogIndex

export const pageQuery = graphql`
    query {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: 5
        ) {
            edges {
                node {
                    id
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        author
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                        tags
                        img {
                            childImageSharp {
                                fluid(maxWidth: 1200) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
